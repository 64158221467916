import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
    name: "dataSlice",
    initialState: {
        currentLocation: localStorage.getItem('location') === null ? "Everywhere" : localStorage.getItem('location'),
        allMoods: [],
        viewMoods: [],
        selectedMood: "All",
        selectedLanguageId: 1,
        allLanguages: [],
        showPostStatusPopup: false,
        showContestPayModal: false,
        posts: [],
        livePosts: [],
        postOffset: 0,
        alertPopup: {
            show: false,
            title: "Alert",
            message: "",
            showLoginBtn: false,
            registerBtn: false
        },
        redirectToContestId: null
    },
    reducers: {
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload;
        },
        setAllMoods: (state, action) => {
            state.allMoods = action.payload;
        },
       
        setAddViewMoods: (state, action) => {
            if (!state.viewMoods.includes(action.payload)) {
                state.viewMoods.push(action.payload);
            }
        },
        setRemoveViewMoods: (state, action) => {
            if (state.viewMoods.includes(action.payload)) {
                state.viewMoods = state.viewMoods.filter(mood => mood != action.payload)
            }
        },
        setSelectedMood: (state, action) => {
            state.selectedMood = action.payload
        },
        setSelectedLanguageId: (state, action) => {
            state.selectedLanguageId = action.payload;
        },
        setShowHidePostStatusPopup: (state, action) => {
            state.showPostStatusPopup = action.payload;
        },
        setShowAlertPopup: (state, action) => {
            state.alertPopup = action.payload;
        },
        setAllLanguages: (state, action) => {
            state.allLanguages = action.payload;
        },
        setPostsInBulk: (state, action) => {
            //   console.log('first render posts',action.payload)
            state.posts = action.payload;
        },
        setAddInPosts: (state, action) => {
            //   console.log('new post',action.payload.length)
            if (action.payload?.length !== 0) {
                state.posts = [...state.posts, ...action.payload];
            }
            //  state.posts = state.posts.concat(action.payload);

            // action.payload.forEach(post => {
            //     state.posts.push(post)
            // })
            // state.posts.push(...action.payload);
        },
        setLivePost: (state, action) => {
            state.livePosts = action.payload;
        },
        setNewLivePost: (state, action) => {
            console.log("pay",action.payload)
            if (action.payload?.length !== 0) {
                state.livePosts = [...state.livePosts, ...action.payload];
            }
        },
        setRedirectToContest: (state, action) => {
            state.redirectToContestId = action.payload;
        },
        setShowContestPayModal: (state, action) => {
            state.showContestPayModal = action.payload;
        },
    }
})

export const {
    setCurrentLocation,
    setAllMoods,
    setAddViewMoods,
    setRemoveViewMoods,
    setSelectedMood,
    setSelectedLanguageId,
    setShowHidePostStatusPopup,
    setShowAlertPopup,
    setAllLanguages,
    setPostsInBulk,
    setAddInPosts,
    setRedirectToContest,
    setLivePost,
    setNewLivePost,
    setShowContestPayModal
} = dataSlice.actions;

export default dataSlice.reducer;