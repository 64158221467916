import React from 'react'

const Chip = ({ label, isSelected, onClick }) => {
    return (
        <button
            className={`chip-button ${isSelected ? "selected" : ""}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};


export default Chip
