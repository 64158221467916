import React from 'react'
import { Colors } from '../../constants/colors'

const ContestPayModal = ({ handleCancel, handlePay, btnName, message }) => {
    return (
        <>
            {/* Backdrop */}
            <div className="modal-backdrop show"></div>

            {/* Modal */}
            <div className="modal show d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Payment Confirmation</h5>
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCancel}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p dangerouslySetInnerHTML={{
                                __html: message.replace(/\n/g, "<br />"),
                            }} ></p>
                            {/* <p>{message}</p> */}
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn text-white"
                                onClick={handlePay}
                                style={{ backgroundColor: Colors.primary }}
                            >
                                {btnName}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContestPayModal
