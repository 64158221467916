import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dataSlice from "./dataSlice";
import registrationSlice from "./registrationSlice";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage
}

const reducers = combineReducers({
    data: dataSlice,
    registrationData: registrationSlice
})

const persistedReducer = persistReducer(persistConfig, reducers)
const store = configureStore({
    reducer: persistedReducer,
})

const persistor = persistStore(store)

export { store, persistor }

// export default configureStore(
//     {
//         reducer: {
//             data: dataSlice,
//             registrationData: registrationSlice
//         }
//     }
// )