import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Brand from '../components/Brand';
import { customRequest } from '../functions/request';
import { useDispatch } from 'react-redux';
import { setRedirectToContest, setShowAlertPopup } from '../redux/dataSlice';
import { getLoggedUser } from '../functions/helper';
import moment from 'moment';
import CustomModal from '../components/CustomModal';
import Moment from 'react-moment';
import Spinner from "react-spinkit";
import CustomButton from '../components/CustomButton';
import AcceptChallengeWithDateOption from '../components/acceptChallengeWithDateOption';
import OverlayLoader from '../components/OverlayLoader';
import ContestHelmet from '../components/helmet/contestHelmet';

const ShareContest = () => {
  const params = useParams();
  const [contest, setContest] = useState({})
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showCalender, setShowCalender] = React.useState(false)
  const [participation_date, setParticipation_date] = React.useState(new Date());
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = getLoggedUser()
  const [contestAlert, setContestAlert] = useState({ visible: false, message: '' });

  const acceptChallenge = () => {

    if (!user) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Login to continue",
            showLoginBtn: true
          }
        ))
      dispatch(setRedirectToContest(params.contestId))
      return
    }
    setLoading(true)
    customRequest('contest/participate-contest', { contest_id: contest.id,participation_date }).then(res => {
      setContestAlert({ visible: true, message: res.message })
      setLoading(false)
    }).catch(e =>
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        )
        // console.log(e)
      ))
  }

  const handleParticipate = () => {
    if (moment().isAfter(moment(contest.end_date))) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Oops! this contest is closed.",
          }
        )
      )
    } else {
      if (contest.media_type === "external") {
        window.open(contest?.external_media_link, '_blank');
      } else {
        acceptChallenge()
        // navigate('/contestpost', { state: { source: "contest", contest_id: location.state.contest_id, media_type: contest.media_type } })
      }

    }

  }

  useEffect(() => {
    setFetching(true)

    const shareContestUrl = "contest/sharecontest"
    const shareContestUrlWhenUserSignedIn = "contest/singlecontest"

    const body = { contest_id: params.contestId }
    if (user) {
      body.user_id = user.id
    }
    dispatch(setRedirectToContest(null))
    customRequest(user ? shareContestUrlWhenUserSignedIn : shareContestUrl, body)
      .then((res) => {
        if (res.participate_date) {
          setContestAlert({ visible: true, message: 'Already participated' })
          navigate('/contest',{state: { contest_id:params.contestId}})

        }
        if (!!res?.contest) {
          setContest(res.contest)
        } else {
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Contest not found",
              }
            ))
          navigate('/')
        }
      })
      .catch(e => {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Something went wrong",
            }
          ))
      }).finally(() => setFetching(false))


  }, [])

  if (fetching) {
    return <div style={{ height: "100vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Spinner name="ball-scale-multiple" />
    </div>
  }

  return (
    <React.Fragment>
      <div className="main_warp">
        <nav className="navbar sticky-top navbar-light mob-header-bar pb-0">
          <div className="container-fluid px-0">
            <a className="left-icons" onClick={() => { navigate(-1) }}>
              <i className="far fa-arrow-left fa-lg" style={{ color: "#605e5c" }}></i>
            </a>
            <a className="navbar-brand header-text me-auto" href="#">
              <Brand />
            </a>
            <a className="search-icons searchicons" onClick={() => { navigate(-1) }}>
              <img src="assets/images/Arrow-Left.svg" />
            </a>
          </div>
        </nav>
        <div class="aboutheader">
          <img src={contest?.banner_image} class="img-fluid" />
        </div>

        <div className="d-flex flex-column bd-highlight mb-4 p-3 pb-4">
          <div className="py-1 bd-highlight">
            <button type="button" className="btn btn-warning-light">#{contest?.hashtag?.split(" ")[0]}</button>
          </div>
          {
            contest?.details?.map((detail, index) => {
              return (
                <div className="py-1 bd-highlight" key={index}>
                  <h4>{detail?.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                </div>
              )
            })
          }

          <div className="py-1 bd-highlight" style={{ marginBottom: 40 }}>
            <span style={{ display: "block", fontWeight: "600" }}>Note : </span>
            {/* <span style={{ display: "block", fontSize: 14 }}>Start Date: <Moment format='hh:mm A DD/MM/YYYY' date={contest.start_date} /></span> */}
            <span style={{ display: "block", fontSize: 14 }}>Last Entry Date:  <Moment format='DD/MM/YYYY' date={contest?.end_date} /></span>
            {/* <span style={{ display: "block", fontSize: 14 }}>Result Date:  <Moment format='hh:mm A DD/MM/YYYY' date={contest.result_date} /></span> */}

          </div>
          <div className="productdetailfooter" >
            <CustomButton className={"btn-block"} label={"  Accept challenge"} onClick={()=>setShowCalender(true)} />
        </div>
        </div>
      </div>
      {contestAlert?.visible && <CustomModal message={contestAlert?.message} loading={loading} hideModal={() => setContestAlert({ visible: false, message: '' })} />}
      {showCalender && <AcceptChallengeWithDateOption setShowCalender={setShowCalender} handleParticipate={handleParticipate} setParticipation_date={setParticipation_date} participateDate={participation_date} startDate={contest?.start_date} endDate={contest?.end_date} />
      }
     {loading && <OverlayLoader />}
     {contest?.id && <ContestHelmet contestName={contest?.hashtag} contestDetails={contest?.details[0]?.title} media={contest?.banner_image} />
      }   
    </React.Fragment >
  )
}

export default ShareContest

