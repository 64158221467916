import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setShowAlertPopup } from '../redux/dataSlice';
import { MdLockOutline } from 'react-icons/md';
import { Colors } from '../constants/colors'

function DayCard({ contestId, details, banner, hashTag, contestUnit, isPaidType, extendAfterDays, contestType, paymentStatus }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let isLock = false;

  if (paymentStatus !== "1") {
    isLock = isPaidType !== "free" && details?.name_of_day > extendAfterDays;
  }
  // const isLock = paymentStatus === "1" ? false : isPaidType !== "free" && details?.name_of_day >= extendAfterDays + 1 && paymentStatus !== "1" 

  const onClick = () => {
    if (isLock) {
      // dispatch(setShowContestPayModal(true))
      return
    } else {
      if (isFutureDate(details?.participate_date)) {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Can't see the content of upcoming days.",
            }
          )
        )
        return;
      }
      navigate('/challenge-content', { state: { banner: banner, contestId: contestId, day_id: details.id, hashTag: hashTag, contestUnit: contestUnit, contestType } })
    }

  }

  const isFutureDate = (dateString) => {
    const currentDate = moment();
    const inputDate = moment(dateString);

    return inputDate.isAfter(currentDate);
  };


  const cardBgColor = {
    done: '#21B05C',
    pending: '#C4A489',
    toBeDone: '#cfcec2',
    new: '#ffffff'
  }


  // console.log(isFutureDate(details?.participate_date))


  const styles = {
    card: {
      backgroundColor: details?.contest_days_user[0]?.status?.toLowerCase() === 'done' ? '#21B05C' : isFutureDate(details?.participate_date) ? '#D3D3D3' : '#ffffff',
      // border: 'none',
      // borderRadius: '4px',
      // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      // transition: 'box-shadow 0.3s ease-in-out',
      // cursor: 'pointer',
      // marginTop: '15px',
      // margin: '10px',
      // justifyContent: 'center',
      // alignItems: 'center',
      // height: '10vh',
      // width: '10vh',
      // display: 'inline-flex',
    },

  }

  let cardClass = 'card'; // Default class

  if (window.innerWidth <= 768) {
    cardClass = 'card-small'; // Apply class for screens <= 768px
  } else if (window.innerWidth <= 480) {
    cardClass = 'card-tiny'; // Apply class for screens <= 480px
  }


  return (

    <button className='card day-card' onClick={onClick} style={styles.card} >
      <h4 >{`Day ${details?.name_of_day}`}</h4>
      {isLock && <MdLockOutline size={20} color={Colors.grey} />}
    </button>

  )
}

export default DayCard 