// import logo from './logo.svg';
import "./App.css";
import NavigationManager from "./routes/navigation";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import PostStatusPopup from "./components/postStatusPopup";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Config from "./config";
import { PersistGate } from 'redux-persist/integration/react'

function App() {






  return (
    <GoogleOAuthProvider clientId={Config.CLIENT_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PostStatusPopup />
          <NavigationManager />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}


export default App;
