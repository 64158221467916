import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostCard } from '../../components/postCard';
import { customRequest } from '../../functions/request';
import { setShowAlertPopup, setShowContestPayModal } from '../../redux/dataSlice';
import moment from "moment";
import DayCard from '../../components/DayCard';
import { getLoggedUser } from '../../functions/helper';
import HorizontalContestTab from '../../components/horizontalContestTab';
import CustomModal from '../../components/CustomModal';
import Brand from '../../components/Brand';
import SpinnerOverlay from '../../components/OverlayLoader';
import SadhanaDetails from './sadhanaDetails';
import { Colors } from '../../constants/colors';
import ContestPayModal from '../../components/contestPayModal';
import { MdArrowBack } from 'react-icons/md';

// const tabsData = [
//   'Sadhana Details',
//   'Sadhana Schedule',
//   'Entries',
//   'Leaderboard',
//   // Add more tabs as needed
// ];


let tabsData = [];

const box = {
  pending: {
    title: "Check Status to Unlock Your Access", color: Colors.yellow,
    message: "Complete the payment to proceed to the next step.",
    btnName: "Proceed to Pay"
  },
  1: {
    title: "You Have Unlocked the Access", color: Colors.green,
    message: "Success! You've unlocked the next step. Thank you. Keep going!",
    btnName: "Continue"

  },
  0: {
    title: "Pay to unlock your Access", color: Colors.yellow,
    message: `Payment is processing.Hang tight.If your payment was successful, you will get access to the paid features soon.
    Retry Payment if your payment failed earlier.
    OR Pay Now if you haven't paid earlier.`,
    btnName: "Proceed to Pay"

  },
  failed: {
    title: "Try again to Unlock Your Access", color: Colors.red,
    message: "Your earlier payment attempt failed. Please try again to proceed.",
    btnName: "Retry Payment"
  }
}
export default function ContestScreen() {
  let navigate = useNavigate();
  let location = useLocation();
  let [contest, setContest] = React.useState(null);
  const [posts, setPosts] = React.useState(null);
  const [winnerPosts, setWinnerPosts] = React.useState(null);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState({
    id: "Sadhana Details",
    name: `${location.state?.contestType} Details`
  },); const [contestDays, setContestDays] = React.useState([]);
  const [contestAlert, setContestAlert] = React.useState({ visible: false, message: '' });
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [isParticipated, setisParticipated] = React.useState(false)
  const [showCalender, setShowCalender] = React.useState(false)
  const [participation_date, setParticipation_date] = React.useState(new Date());
  const { showContestPayModal } = useSelector(state => state.data)
  const [isInterested, setIsInterested] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [retry, setRetry] = useState(false)
  const [participationId, setParticipationId] = React.useState(null)


  const showInterest = async () => {
    try {
      setLoading(true)
      const body = { "participation_id": participationId }
      const resp = await customRequest("contest/contest-extend-interest", body)
      setLoading(false)
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: resp?.message,
          }
        )
      )
      return resp
    } catch (error) {
      setLoading(false)
      throw error
    }
  }
  const handlePay = async () => {
    try {
      if (paymentStatus === "1") {
        dispatch(setShowContestPayModal(false))
        return
      }
      dispatch(setShowContestPayModal(false))

      if (!isInterested) {
        await showInterest()
        setRetry(!retry)
      }
      if (!contest?.payment_link) {
        return dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Invalid payment link",
            }
          )
        )
      }
      if (contest?.payment_link) {
        window.open(contest?.payment_link, '_blank')?.focus()
      }
    } catch (error) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: error?.message,
          }
        )
      )
    }
  }



  const getContestPosts = () => {
    customRequest('post/contest-posts', { contest_id: location?.state?.contest_id }).then((res) => {
      setPosts(
        res.posts.map(post => {
          return (
            <PostCard key={post?.id} post={post} />
          )
        })
      )
    })
  }

  const getContest = () => {
    const user = getLoggedUser()
    customRequest('contest/singlecontest', { contest_id: location?.state?.contest_id, user_id: user?.id }).then((res) => {
      setParticipationId(res?.participation_id)
      setPaymentStatus(res?.payment_status)
      if (res.participate_date) {
        setisParticipated(true)
        //user already participated
        setSelectedTab(tabsData[1])
        let temp = []
        res.contestDays?.forEach((element, index) => {
          const item = {
            ...element,
            participate_date: index === 0 ? moment(res.participate_date)?.format('L') : moment(res.participate_date)?.add(index, 'days').format('L')

          }
          temp.push(item)
        });
        setContestDays(temp)
      } else {
        setContestDays(res.contestDays ? res.contestDays : [])
      }
      setContest(res.contest);
      setStartDate(res.participate_date)
      setWinnerPosts(res.winnerPosts);
    }).catch((e) => {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        ))
    })
    getContestPosts();
  }



  const acceptChallenge = () => {
    // console.log(contest)
    setLoading(true)
    // navigate('/contestlist',{state:{redirectToMyChallenge:true}})
    customRequest('contest/participate-contest', { contest_id: contest?.id, participation_date }).then(res => {
      //   console.log(res)
      setContestAlert({ visible: true, message: res.message })
      setLoading(false)

      // nav
    }).catch(e =>
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        )
        // console.log(e)
      )).finally(() => setLoading(false))
  }

  const handleParticipate = () => {
    if (getLoggedUser()) {
      if (moment().isAfter(moment(contest.end_date))) {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Oops! this contest is closed.",
            }
          )
        )
      } else {
        if (contest.media_type === "external") {
          window.open(contest?.external_media_link, '_blank');
        } else {
          acceptChallenge()
          // navigate('/contestpost', { state: { source: "contest", contest_id: location.state.contest_id, media_type: contest.media_type } })
        }

      }
    } else {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Please login to participate.",
            showLoginBtn: true
          }
        )
      )
    }
  }

  const handleSelectTab = (tab) => {
    setSelectedTab(tab)
  }

  React.useEffect(() => {
    const tabs = [
      {
        id: "Sadhana Details",
        name: `${location.state?.contestType} Details`
      },
      {
        id: "Sadhana Schedule",
        name: `${location.state?.contestType} Schedule`
      },
      {
        id: "Entries",
        name: "Entries"
      },
      {
        id: "Leaderboard",
        name: "Leaderboard"
      }
    ]
    tabsData = tabs
    getContest();
  }, [retry])

  if (contest == null) {
    return <div></div>
  }

  return (
    <React.Fragment>
      {loading && <SpinnerOverlay />}
      <div class="main_warp">
      <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
          <MdArrowBack size={24} onClick={() => { navigate(-1) }} cursor="pointer" />
          <h4 style={{
            fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5,
          }}>
            {`#${contest?.hashtag}` || "Sadhana"}
          </h4>
          <a className="search-icons searchicons" style={{ marginLeft: 'auto' }} onClick={() => { navigate(-1) }}>
            <img src="assets/images/Arrow-Left.svg" />
          </a>
        </div>
        {/* <nav class="navbar sticky-top navbar-light mob-header-bar pb-0">
          <div class="container-fluid px-0">
            <a class="left-icons" onClick={() => { navigate(-1) }}>
              <i class="far fa-arrow-left fa-lg" style={{ color: "#605e5c" }}></i>
            </a>
            <h4 style={{
              fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5,
            }}>
              {`#${contest?.hashtag}` || "Sadhana"}
            </h4>
            <a class="search-icons searchicons" onClick={() => { navigate(-1) }}>
              <img src="assets/images/Arrow-Left.svg" />
            </a>
          </div>
        </nav> */}
        <div class="aboutheader">
          <img src={contest.banner_image} class="img-fluid" />
        </div>
        <HorizontalContestTab hideScheduleTab={contestDays[0]?.participate_date ? false : true} tabsData={tabsData} selectedTab={selectedTab} handleSelectTab={handleSelectTab} />

        <div class="container px-0">
          <div class="productdetail">

            <div class="productdetailview" style={{ padding: 0 }}>
              {selectedTab?.id === 'Sadhana Details' &&
                <SadhanaDetails
                  contest={contest} isParticipated={isParticipated}
                  handleParticipate={handleParticipate}
                  participateDate={participation_date}
                  setParticipation_date={setParticipation_date}
                  showCalender={showCalender}
                  setShowCalender={setShowCalender}
                />}
              {selectedTab?.id === 'Sadhana Schedule' && <div
                style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}
              >
                {/* {!!contestDays?.length && Array.isArray(contestDays) && contestDays?.map((elm, index) => {
                  return contest?.is_paid_type !== "free" && index + 1 === contest?.extend_after_days + 1 ?
                    <div className="flex-row day-card-extend" >
                      <button className='card day-card text-white'
                        style={{
                          backgroundColor: box[paymentStatus]?.color
                            || (isInterested
                              ? box["pending"]?.color
                              : Colors.danger)
                        }}
                        onClick={() => dispatch(setShowContestPayModal(true))
                        }>
                        <h5 className='text-center text-white p-1'>
                          {
                            box[paymentStatus]?.title
                            || (isInterested
                              ? box["pending"]?.title
                              : "Pay to Unlock Your Access")
                          }
                        </h5>
                      </button>
                      <DayCard
                        isPaidType={contest?.is_paid_type}
                        extendAfterDays={contest?.extend_after_days}
                        amount={contest?.amount}
                        key={index}
                        details={elm}
                        startDate={startDate}
                        banner={contest?.banner_image}
                        paymentStatus={paymentStatus}
                        contestId={contest?.id} hashTag={contest?.hashtag}
                        contestUnit={contest?.details[0]?.contest_unit_label} />
                    </div>
                    : <DayCard
                      contestType={location.state?.contestType}
                      isPaidType={contest?.is_paid_type}
                      extendAfterDays={contest?.extend_after_days}
                      amount={contest?.amount}
                      key={index}
                      details={elm}
                      startDate={startDate}
                      banner={contest?.banner_image}
                      paymentStatus={paymentStatus}
                      contestId={contest?.id} hashTag={contest?.hashtag}
                      contestUnit={contest?.details[0]?.contest_unit_label} />
                }
                )} */}
                {!!contestDays?.length && Array.isArray(contestDays) && contestDays?.map((elm, index) => (
                  <DayCard key={index} details={elm} startDate={startDate} banner={contest?.banner_image} contestId={contest?.id} hashTag={contest?.hashtag} contestUnit={contest?.details[0]?.contest_unit_label} />
                ))}
              </div>}
              {selectedTab?.id === 'Entries' && <div >

                {
                  posts
                }
              </div>}

              {selectedTab?.id === 'Leaderboard' && <div >
                {
                  winnerPosts?.length !== 0
                    ?
                    winnerPosts?.map((post, index) => {
                      return (
                        <PostCard key={index} post={post} />
                      )
                    })
                    :
                    <div style={{ height: 260, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: '0px' }}>
                      <img src={require("../../assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
                      <span>Details will get updated soon.</span>
                    </div>
                }
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal windowmodal fade"
        id="Participate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header px-3">
              <div
                class="modal-close ml-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/images/Arrow-Left.svg" />
              </div>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-column bd-highlight text-center">
                <div class="p-2 bd-highlight">
                  <div class="modallogoimg">
                    <img src="assets/images/Appicon.png" class="img-fluid" />
                  </div>
                </div>
                <div class="p-2 bd-highlight text-center">
                  <h2>Install the app..</h2>
                  <p class="text-center">Install the app today &amp; get access to Amet minim mollit non<br />deserunt ullamco est sit aliqua dolor do amet sint. </p>
                </div>
                <div class="pb-2 bd-highlight">
                  <div class="playstore"><a href="#"><img src="assets/images/Playstore.png" class="img-fluid" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {contestAlert?.visible && <CustomModal message={contestAlert?.message} hideModal={() => setContestAlert({ visible: false, message: '' })} />}
      {showContestPayModal &&
        <ContestPayModal
          handlePay={handlePay}
          amount={contest?.amount}
          isPaidType={contest?.is_paid_type}
          btnName={box[paymentStatus]?.btnName ?? "Proceed to Pay"}
          paymentStatus={paymentStatus}
          message={box[paymentStatus]?.message ?? "Complete the payment to proceed to the next step"}
          handleCancel={() => dispatch(setShowContestPayModal(false))} />}
    </React.Fragment>
  )
}



// <div class="productdetailnav">
// <ul class="nav nav-tabs" id="myTab" role="tablist">
//   <li class="nav-item addbtn" role="presentation">
//     <button
//       onClick={() => { setSelectedTab('contest_details') }}
//       class="nav-link active"
//       id="Contest-tab"
//       data-bs-toggle="tab"
//       data-bs-target="#Contest"
//       type="button"
//       role="tab"
//       aria-controls="Contest"
//       aria-selected={selectedTab == "contest_details" ? "true" : "false"}
//     >
//       Challenge details
//     </button>
//     {
//       selectedTab == "contest_details"
//         ?
//         <span class="sliderlineactive"></span>
//         :
//         null
//     }
//   </li>
//   <li class="nav-item addbtn" role="presentation">
//     <button
//       onClick={() => { setSelectedTab('challenge') }}
//       class="nav-link"
//       id="challenge-schedule-tab"
//       data-bs-toggle="tab"
//       data-bs-target="#challenge"
//       type="button"
//       role="tab"
//       aria-controls="challenge"
//       aria-selected={selectedTab == "challenge" ? "true" : "false"}
//     >
//       {/* Participants */}
//       Challenge Schedule
//     </button>
//     {
//       selectedTab == "challenge"
//         ?
//         <span class="sliderlineactive"></span>
//         :
//         <span class="sliderline"></span>
//     }
//   </li>
//   <li class="nav-item addbtn" role="presentation">
//     <button
//       onClick={() => { setSelectedTab('entries') }}
//       class="nav-link"
//       id="entries-tab"
//       data-bs-toggle="tab"
//       data-bs-target="#entries"
//       type="button"
//       role="tab"
//       aria-controls="entries"
//       aria-selected={selectedTab === "entries" ? "true" : "false"}
//     >
//       {/* Winner */}
//       Entries
//     </button>
//     {
//       selectedTab == "entries"
//         ?
//         <span class="sliderlineactive"></span>
//         :
//         <span class="sliderline"></span>
//     }
//   </li>
//   <li class="nav-item addbtn" role="presentation">
//     <button
//       onClick={() => { setSelectedTab('winner') }}
//       class="nav-link"
//       id="winner-tab"
//       data-bs-toggle="tab"
//       data-bs-target="#winner"
//       type="button"
//       role="tab"
//       aria-controls="winner"
//       aria-selected={selectedTab == "winner" ? "true" : "false"}
//     >
//       Winner
//     </button>
//     {
//       selectedTab == "winner"
//         ?
//         <span class="sliderlineactive"></span>
//         :
//         <span class="sliderline"></span>
//     }
//   </li>
// </ul>
// </div>

