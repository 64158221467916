import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const ContestHelmet = ({ contestName = "Bhagwan.live", contestDetails = "Sadhana", media = "" }) => {


    useEffect(() => {
        const defaultImage = `${process.env.PUBLIC_URL}/og_image.png`;
        const og_image = media || defaultImage;
        document.querySelector('meta[property="og:image"]').setAttribute("content", og_image);
    }, [media])


    return (
        <Helmet>
            <title>{contestName}</title>
            <meta name="description" content={contestDetails} />
            <meta property="og:title" content={contestName} />
            <meta property="og:description" content={contestDetails} />
        </Helmet>
    );
};

export default ContestHelmet;