// const rootlink = "http://localhost:3060/api";
// const imageRootlink = "http://localhost:3060/uploads";
// const videoRootlink = "http://localhost:3060/uploads";
// const contestImageLink = "http://localhost:8000/storage/";
// const postShareLink = "http://localhost:3000/post/"

import Config from "../config";

const defaultUserImageLink = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
const rootlink = Config.BASE_URL
//const rootlink="https://node157415-localbol-nodejs-api-production.cloudjiffy.net/api"
//const rootlink="https://localbol-api.cloudjiffy.net/api"
const imageRootlink = "https://localbol-nodejs-api-production.cloudjiffy.net/uploads";
const videoRootlink = "https://localbol-nodejs-api-production.cloudjiffy.net/uploads";
const postShareLink = "https://bhagwan.live/post/"
const contestShareLink = `${window.location.origin}/contest`
const googleAuthUrl = "https://www.googleapis.com/oauth2/v3/userinfo"
const contestImageLink = "http://localbol-admin-production.cloudjiffy.net/storage/";
const emailId = "mybhagwanlive@gmail.com"
export {
    rootlink,
    imageRootlink,
    videoRootlink,
    defaultUserImageLink,
    postShareLink,
    contestImageLink,
    emailId,
    contestShareLink,
    googleAuthUrl
}
