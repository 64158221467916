import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const PostHelmet = ({ title = "post", media = "", user = "Bhagwan.Live user", description = "Bhagwan.Live post" }) => {

    useEffect(() => {
        const defaultImage = `${process.env.PUBLIC_URL}/og_image.png`;
        const og_image = media || defaultImage;
        document.querySelector('meta[property="og:image"]').setAttribute("content", og_image);
    }, [media])


    return (
        <Helmet>
            <title>{user}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    );
};

export default PostHelmet;