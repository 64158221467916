import React from 'react'
import { MdChevronLeft } from 'react-icons/md'
import CustomButton from '../../../components/CustomButton'
import { Colors } from '../../../constants/colors'
import SwipeComponent from './Slides'
import useToday from './hook/useToday'
import SadhanaSlide from './carousel/challengeSlides'
import CommunitySlide from './carousel/communitySlides'
import Loader from '../../../components/Loader'
import LazyLoadImage from '../../../components/lazyLoadImage'

const Today = ({ onShowCommunity }) => {
  const { setShowMandir, showMandir, showCommunity, handleAllSadhanas, handleMySadhana, showSadhana, setShowSadhana, setShowCommunity, selectedImage, setSelectedImage, slideImages, collection, handleCreatePost, loading } = useToday()



  if (loading) {
    return <Loader />
  }
  return (
    <div className='container-fluid main_warp pb-4' style={{ backgroundColor: Colors.white, height: "100vh", width: "100vw", overflowY: "scroll" }}>
      <div className='mt-3 pt-3 px-3 shadow' style={{ backgroundColor: Colors.white, height: "calc(100vh - 25%)" }}>
        <div className="p-2" style={{ height: "10%" }} >
          <div className="p-2 d-flex justify-content-between align-items-center fs-5 font-weight-bold">
            <p className="m-0" style={{ color: Colors.purple }}>My Mandir</p>
            {/* <MdChevronLeft onClick={()=>setShowMandir(!showMandir)} size={25} style={{ transform: 'rotate(-90deg)' }} /> */}
          </div>
        </div>
        {showMandir &&
          <div style={{ height: "100%", justifyContent: "space-between" }}>
            <div style={{ height: "70%", width: "100%" }}>
              <div style={{ height: "100%", width: "100%" }}>
                <SwipeComponent slides={slideImages} />
              </div>
            </div>

            <div className='d-flex mt-2 py-2' style={{ overflow: "scroll", justifyContent: "space-between" }}>
              {
                collection?.map(elm => (
                  <LazyLoadImage onClick={() => setSelectedImage(elm)} src={elm?.category_image} key={elm.id} className='image-fluid mx-2 mb-0 '
                    style={{
                      width: "4.5rem",
                      height: "4.5rem",
                      borderRadius: "50%", // half of the height and width
                      boxShadow: elm?.id === selectedImage?.id
                        ? `0 0 0px 2px ${Colors.purple}, 0 0px 10px 4px ${Colors.primary}`
                        : 'none',

                    }} />
                ))
              }

            </div>
          </div>
        }

      </div>

      <div className='mt-3 p-3 shadow' style={{ backgroundColor: Colors.white }}>
        <div onClick={() => setShowSadhana(!showSadhana)} className="p-2" >
          <div className="p-2 d-flex justify-content-between align-items-center fs-5 font-weight-bold">
            <p className="m-0" style={{ color: Colors.purple }}>Sadhana</p>
            <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
          </div>
        </div>
        {showSadhana &&
          <div className='p-1' >
            <p className='mt-2'>Start Your Devotion Journey with Bhagwan Live</p>
            {/* <div className='d-flex align-items-center'>
              <img src={Sharing} alt="share_icon"  />
              <p className='ml-2 m-0' style={{fontSize:"0.9rem"}}> <b>Join</b>,<b> practice</b> , and <b>connect</b> with others.  </p>
            </div>

            <div className='d-flex align-items-center mt-2'>
              <img src={Fire} alt="fire_icon"  />
              <p className='ml-2 m-0'style={{fontSize:"0.9rem"}} >Let  <b>devotion</b>  lead you to better well-being.   </p>

            </div>
            <div className='d-flex align-items-center mt-2'>
              <img src={Signup} alt="signup_icon"  />
              <p className='ml-2 m-0' style={{fontSize:"0.9rem"}}> <b>Sign up now</b> and begin your transformative journey! </p>

            </div> */}
            <SadhanaSlide />
            <div className='p-2 d-flex justify-content-between align-items-center'>
              <CustomButton onClick={handleAllSadhanas} label={"All Sadhanas"} className={"btn-block mr-2"} color={Colors.purple500} />
              <CustomButton onClick={handleMySadhana} label={"My Sadhana"} className={"btn-block"} color={Colors.orange500} />
            </div>
          </div>
        }

      </div>

      <div className='mt-3 p-3 shadow' style={{ backgroundColor: Colors.white }}>
        <div onClick={() => setShowCommunity(!showCommunity)} className="p-2" >
          <div className="p-2 d-flex justify-content-between align-items-center fs-5 font-weight-bold">
            <p className="m-0" style={{ color: Colors.purple }}>Community</p>
            <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
          </div>
        </div>
        {showCommunity &&
          <div className='p-1' >
            <p className='mt-2 '>Explore Bhagwan Live Community for</p>
            {/* <div className='d-flex align-items-center mt-2'>
              <img src={Poster} alt="fire_icon"  />
              <p className='ml-2 m-0' style={{fontSize:"0.9rem"}}><b> Poster Maker: </b>Design stunning business or personal branding posters effortlessly for free with our easy Poster Maker tool.   </p>

            </div>  <div className='d-flex align-items-center mt-2'>
              <img src={Pray} alt="fire_icon"  />
              <p className='ml-2 m-0' style={{fontSize:"0.9rem"}}><b>Spiritual Content:</b> Dive into a spiritual oasis with Bhagwan images, soul-soothing videos, and enlightening quotes. </p>

            </div>  <div className='d-flex align-items-center mt-2'>
              <img src={Earth} alt="fire_icon"  />
              <p className='ml-2 m-0' style={{fontSize:"0.9rem"}}><b>Hyperlocal Community:</b> Connect with like-minded people in your area, sharing your language and interests. </p>

            </div> */}
            <CommunitySlide />
            <div className='p-2 d-flex justify-content-between align-items-center'>
              <CustomButton onClick={onShowCommunity} label={"Visit Community"} className={"btn-block mr-2"} color={Colors.orange500} />
              <CustomButton onClick={handleCreatePost} label={"Create Post"} className={"btn-block"} color={Colors.purple500} />

            </div>
          </div>
        }

      </div>

    </div>
  )
}

export default Today